import * as React from "react";
import { Container } from "react-bootstrap";
import { graphql } from "gatsby";
import { SiteMetaDataFields } from "../fragments/NodeSiteMetadataFields";
import { WmkSeo } from "wmk-seo";
import styled from "styled-components";
import { PageQuery } from "../fragments/NodePageFields";
import Hero from "../components/ui/Hero";
import { RichText, RichTextOptions, RichTextReact } from "wmk-rich-text";
import { options } from "../components/Blocks/richTextOptions";
import CtaBlock from "../components/ui/CtaBlock";

const FullScreenContainer = styled(Container)<{ bg?: string }>`
  width: 100%;
  min-height: 100vh;
  ${({ bg }) => (bg ? `background-color: ${bg};` : ``)};
  padding-left: 0 !important;
  padding-right: 0 !important;
`;

// const FontWrapper = styled.div`
//   font-family: Urbane, sans-serif;
// `;

const IndexPage = ({ data }: { data: IndexPageQuery }) => {
  const ctaData = data.page.callToAction;
  return (
    <>
      <WmkSeo.Meta
        title={"Break the Stigma"}
        siteTitle={data.site.siteMetadata.title}
        baseUrl={data.site.siteMetadata.baseUrl}
        slug="/"
      />
      <FullScreenContainer fluid>
        <Hero heroData={data.page.hero} />
        <RichTextReact
          content={new RichText(data.page.content)}
          options={options as RichTextOptions}
        />
        <CtaBlock
          headline={ctaData.headline}
          subheader={ctaData.subhead}
          copy={ctaData.copy}
          link={ctaData.action}
        />
      </FullScreenContainer>
      {/* <FullScreenContainer fluid bg={colors.blue.toString()}>
      <Row>
        <Col style={{ paddingTop: `45vh`, textAlign: "center" }}>
          <FontWrapper>
            <Row className="flex-column">
              <Col>
                <h1 style={{ fontWeight: 900, color: colors.teal.toString() }}>
                  BREAK{" "}
                  <s style={{ color: colors.lightGreen.toString() }}>
                    THE STIGMA
                  </s>
                </h1>
              </Col>
              <Col style={{ color: colors.white.toString() }}>
                Website Coming Soon!
              </Col>
            </Row>
          </FontWrapper>
        </Col>
      </Row>
    </FullScreenContainer> */}
    </>
  );
};

export default IndexPage;

interface IndexPageQuery {
  site: SiteMetaDataFields;
  page: PageQuery;
}

export const query = graphql`
  {
    page: contentfulPage(title: { eq: "Home" }) {
      ...NodePageFields
    }
    site {
      ...NodeSiteMetadata
    }
  }
`;
